import * as React from "react";

export function calculateScore(distance: number, isInRightCountry: boolean): number{
	const countryBonus = isInRightCountry ? 2500 : 0;
	const score = (5000 + countryBonus) * Math.exp(-distance / 1000000)
	return Math.round(score);

}

export interface UserScore {
	username: string;
	userId: string;
	score: number;
}
/** Sorts an array of {@link UserScore} objects by score and then by username. */
export function sortUserScores(userScores: UserScore[]) {
	return userScores.sort((a, b) => b.score - a.score || a.username.localeCompare(b.username));
}
