import * as React from "react";
import { Profile } from "src/components/profile";
import { UserScore } from "src/modules/gameApi";

import "src/styles/playerList.css"

export interface PlayerListProps {
	roundScores: UserScore[];
	totalScores: UserScore[];
	displayScores?: boolean;
}

export function PlayerList(props: PlayerListProps){
	const [showTotal, setShowTotal] = React.useState(false);
	function toggleShowTotal() {
		setShowTotal(!showTotal);
	}

	return <div className="player-list-container animation-wrapper-load">
		{/* <button onClick={toggleShowTotal}>{showTotal ? "Show round" : "Show total"}</button> */}
		<div onClick={toggleShowTotal} className="player-list-tab-container" data-showtotal={showTotal}>
			<span className="player-list-tab-round">Round</span>
			<span className="player-list-tab-total">Total</span>
		</div>
		<table className="player-list-table">
			<thead>
					<tr>
						<th>Player</th>
						{props.displayScores && <th>Score</th>}
					</tr>
				</thead>
			<tbody>
				{
				(showTotal ? props.totalScores : props.roundScores).map((player, index) => {
					return (
						<tr className="player-list-player" key={index}>
							<td className="player-list-profile"><Profile username={player.username} id={player.userId}/></td>
							{props.displayScores && <td className="player-list-score">{player.score && <span>{player.score}</span>}</td>}
						</tr>
					)
				})
				}
			</tbody>
		</table>
	</div>
}
