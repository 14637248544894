import {Game, GameModel, GameRules} from "./gameModel";
import {User, UserModel, userModel} from "./userModel";
import {AuthModel, authModel} from "./authModel";
import {NavigateFunction} from "react-router-dom";
import { Leaderboard } from "./leaderboardModel";
import { LeaderboardData } from "./leaderboardModel";
import { PromiseHandler } from "./promiseHandler";


/** Represents resolved data needed for the leaderboard.  */
export interface LeaderboardAndUid {
	/** An array of all leaderboard entries to be rendered. */
	leaderboard: LeaderboardData;
	/** ID of the currently logged in user, to highlight own highscore. */
	currentUserId?: string;
}

/** Represents resolved data needed for a game. */
export interface GameData {
	game: Game;
	user: User;
}
/** Same as {@link GameData}, but with an additional redirectRoute parameter. */
export interface GameDataRedirect extends GameData {
	/** If not null, contains a /game route with the correct game id, prompting a redirect to it in gamePresenter. */
	redirectRoute?: string;
}

/** Object representing the current session. Contains all data needed for this client. */
export class Session {
	gameModel: GameModel = new GameModel();
	userModel: UserModel = userModel;
	auth: AuthModel = authModel;
	leaderboard: Leaderboard = new Leaderboard();
	leaderboardHandler = new PromiseHandler<LeaderboardAndUid>(this.getLeaderboardData());

	async getGameData(urlGameId: string | null): Promise<GameDataRedirect> {
		let game = await this.gameModel.getPromise();
		const user = await this.userModel.userHandler.promise;

		if (user == null) { // TODO: Instead of throwing error, create a guest user.
			throw Error("You need to be logged in to be in a game.") // TODO: Fix correct error code.
		}

		if (urlGameId != null) {
			if (urlGameId != game?.id) {
				//console.log("Joining existing game...")
				this.gameModel.joinGame(user.firebaseUser.uid, user.name, urlGameId);
				game = await this.gameModel.getPromise();
			}
		}
		if (game == null) {
			game = this.gameModel.createGame(user.firebaseUser.uid, user.name, user.firebaseUser.uid)
		} else if (game.roundIndex >= game.gameRules.numberOfRounds) {
			//console.log("Game has ended, restarting...")
			this.gameModel.restartGame()
			game = await this.gameModel.getPromise();
		}

		return {
			game: game,
			user: user,
			redirectRoute: urlGameId != game.id ? `/game/${game.id}` : null
		}
	}

	startGame() {
		this.gameModel.handler.handlePromise(this.gameModel.handler.result.startGame());
	}

	nextRound() {
		const game = this.gameModel.handler.result;
		const user = this.userModel.userHandler.result;
		game.nextRound();
		const score = game.getUserScore();
		if (game.roundIndex >= game.gameRules.numberOfRounds && user.highScore < score) {
			user.setData(score);
		}
	}

	/** Fetches the leaderboard and awaits the current user ID. */
	async getLeaderboardData(): Promise<LeaderboardAndUid> {
		return {
			leaderboard: await this.leaderboard.getLeaderboard(),
			currentUserId: this.userModel.userHandler.result?.id
		}
	}
}

export interface SessionProps {
	session: Session;
}
