import * as React from "react";
import {Button} from "src/components/button";
import {KeyListener} from "src/components/keyListener";

export interface GameRoundSubmitViewProps {
	onSubmit: () => void;
	disabled: boolean;
}
export function GameRoundSubmitView(props: GameRoundSubmitViewProps) {
	return <div className="button-wrapper-cool">
		<Button className="button-make-guess" onClick={props.onSubmit} disabled={props.disabled}>Confirm guess</Button>
		<KeyListener onKeyPressed={props.onSubmit} listenKey={" "}/>
	</div>
}
