import * as React from "react";
import { Button } from "src/components/button";
import { PlayerList, PlayerListProps } from "src/components/playerList";
import { KeyListener } from "src/components/keyListener";
import { Guess } from "src/model/roundModel";
import "src/styles/game.css"

export interface GameRoundResultsView extends PlayerListProps {
	didUserGuess: boolean;
	isHost: boolean;
	isCountryCorrect: boolean;
	correctCountryText: string;
	userCountryText: string;
	distanceText: string;
	score: number;
	onNextRound: () => void;
	isFinalRound: boolean;
}

export function GameRoundResultsView(props: GameRoundResultsView) {
	return (
		<div className="game-map-results">
			{props.didUserGuess ? (
				<>
					{props.isCountryCorrect ? (
						<>
							<span className="success-guess">Correct!</span>
							{`It was ${props.correctCountryText}!`}
						</>
					) : (
						<>
							<span className="failed-guess">Incorrect!</span>
							{`You guessed ${props.userCountryText} but it was ${props.correctCountryText}.`}
						</>	
					)}
					<br />
					<span>You were <b className={props.isCountryCorrect ? "highlight":""}>{props.distanceText}</b> away.</span>
				</>
			): (
				<span className="didnt-guess">You did not make a guess in time. The correct country was {props.correctCountryText}.</span>
			)}
			
				{props.score != null && props.score !== 0 ? <span>You scored <b className={props.isCountryCorrect ? "highlight":""}>{props.score}</b> points.</span> : (props.didUserGuess && <span>You did not score any points.</span>)}
			{props.isHost && <>
				<div className={`button-wrapper-cool ${props.isFinalRound ? "finish-game" : "next-round"}-button ${props.didUserGuess ? "":"button-didnt-guess"}`}>
					<Button onClick={props.onNextRound} >
						{props.isFinalRound ? "Finish game" : "Next round"}
					</Button>
				</div>
				<KeyListener onKeyPressed={props.onNextRound} listenKey={" "} disabled={!props.isHost}/>
			</>}
			<PlayerList
				roundScores={props.roundScores}
				totalScores={props.totalScores}
				displayScores
			/>
		</div>
	)
}
