import * as React from "react";

interface KeyListenerProperties {
	onKeyPressed: () => void;
	listenKey: string;
	disabled?: boolean;
}

export function KeyListener(props: KeyListenerProperties){
	React.useEffect(() => {
		function onKeyDown(event: KeyboardEvent){
			if (event.key == props.listenKey && !props.disabled) {
				props.onKeyPressed();
			}
		}
		window.addEventListener("keydown", onKeyDown)
		return () => {
			window.removeEventListener("keydown", onKeyDown) // remove the event listener when switching to a different page
		}
	}, [])
	return null;
}