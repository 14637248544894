import * as React from "react";
import {GameData} from "src/model/model";
import {GameEndView} from "src/views/game/gameEndView";
import {GameEndMultiView} from "src/views/game/gameEndMultiView"
import {observer} from "mobx-react-lite";

export interface GameEndProps extends GameData {
	onRestart: () => void;
}
export const GameEnd = observer(({game, user, onRestart}: GameEndProps) => {
	if (game.userIds.length > 1) {
		return <GameEndMultiView 
		userScores={game.getTotalScores()}
		restartGame={onRestart}
	/>
	} else {
		return <GameEndView
		score={game.getUserScore()}
		restartGame={onRestart}
	/>;
	}
})
