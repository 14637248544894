import * as React from "react";
import {Button} from "src/components/button";
import {KeyListener} from "src/components/keyListener";
import {Profile} from "src/components/profile";
import {UserScore} from "src/modules/gameApi";

import "src/styles/style.css";
import "src/styles/form.css";

export interface GameEndProps {
	userScores: UserScore[];
	restartGame: () => void;
}
export function GameEndMultiView({userScores, restartGame}: GameEndProps) {
	
	function onSubmitACB() {
		restartGame()
	}

	return (
        <main className="container" id="result-list">
            <h2>Results!</h2>
            {userScores.map(({username, userId, score}, index) => (
                <div className="result-list-item" key={userId}>
                    <div className="rank">#{index + 1}</div>
                    <Profile username={username} id={userId} />
                    <div className="muted-highlight">{score} <span className="hide-on-mobile">points</span></div>
                </div>
            ))}
			<div className="align-center-element">
				<div className="button-wrapper-cool text-center mt-4">
					<Button className="btn btn-primary" onClick={onSubmitACB}>Return to Lobby</Button>
				</div>
			</div>
            <KeyListener onKeyPressed={onSubmitACB} listenKey={" "}/>
        </main>
    );
}
