import * as React from "react";
import {LeaderboardAndUid} from "src/model/model";
import {PromiseHandler} from "src/model/promiseHandler";
import {PromiseRenderer} from "src/components/promiseRenderer";
import {useAutoAnimate} from '@formkit/auto-animate/react'

import "src/styles/leaderboard.css";

export interface LeaderboardProps {
	/** A promise that will resolve into all leaderboard data. See {@link LeaderboardData} and {@link PromiseHandler}. */
	dataHandler: PromiseHandler<LeaderboardAndUid>;
}
export function LeaderboardView(props: LeaderboardProps) {
	function Leaderboard({result: data}: {result: LeaderboardAndUid}) {

		// Used to animate the movement of players on the leaderboard
		const [parent, enableAnimations] = useAutoAnimate()

		return <div className="leaderboard">
			<table>
				<thead>
					<tr>
						<th scope="col"></th>
						<th scope="col">User</th>
						<th scope="col">Score</th>
					</tr>
				</thead>
				<tbody ref={parent}>
					{data.leaderboard.map((entry, index) =>
						<tr key={entry.userId} className={entry.userId == data.currentUserId ? "current-user" : ""}>
							<td>#{index+1}</td>
							<td>{entry.username}</td>
							<td>{entry.score} <span className="hide-on-mobile">points</span></td>
						</tr>
					)}
				</tbody>
			</table>
		</div>
	}

	return <main className="main-home animation-wrapper-load">
		<h1 className="align-center">Leaderboard</h1>
		<hr />
		<PromiseRenderer promiseHandler={props.dataHandler} resultComponent={Leaderboard} />
	</main>
}
