import * as React from "react";
import QRCode from "react-qr-code"
import {Button} from "src/components/button";
import {Profile, ProfileProps} from "src/components/profile";
import { KeyListener } from "src/components/keyListener";
import {RoundLength} from "src/model/gameModel";
import {useAutoAnimate} from "@formkit/auto-animate/react";

import "src/styles/form.css";
import "src/styles/gameSetup.css";

export interface GameSetupViewProps {
	inviteURL: string;
	isHost: boolean;
	users: ProfileProps[];
	setRoundNumber: (roundNumber: number) => void;
	setRoundLength: (roundLength: RoundLength) => void;
	formSubmit: (event?: React.FormEvent<HTMLFormElement>) => void;
	defaultRoundNumber: number;
	defaultRoundLength: RoundLength;
}

export function GameSetupView(props: GameSetupViewProps) {

	const [parent, enableAnimations] = useAutoAnimate();

	return <main className="game-setup animation-wrapper-load "> {/* the animation causes some z-fighting in Firefox on the stacked semi-transparent boxes */}
		<div className="game-setup-container">
			<form className="game-setup-settings-form" id="gameForm" onSubmit={props.formSubmit}>
				<h2>Game settings</h2>
				<div className="box game-setup-settings-container">

					<div className="game-setup-option-wrapper">
						<label className="game-setup-settings-label" htmlFor="numberOfRounds">Number of rounds</label>
						<input
							type="number"
							name="numberOfRounds"
							min={1} max={10}
							value={props.defaultRoundNumber}
							onChange={(event) => {
								props.setRoundNumber(event.target.valueAsNumber);
							}}
							disabled={!props.isHost}
						/>
					</div>
						
					<div className="game-setup-option-wrapper">
						<label className="game-setup-settings-label" htmlFor="roundLength">Round length</label>
						<div className="box game-setup-round-length">
							{Object.keys(RoundLength).filter(key => isNaN(Number(key)))
							.map(key => ({
								name:'roundLength',
								value: RoundLength[key as keyof typeof RoundLength],
								id: key
							})).map(({ name, value, id }, index)=>{
								return (
								<div className="radio-button-wrapper" key={index}>
									<label htmlFor={name}>{id}:</label>
									<input
										name={name}
										id={id} 
										type="radio"
										value={value}
										onChange={(event) => props.setRoundLength(value)}
										checked={value == props.defaultRoundLength}
										disabled={!props.isHost}
									/>
								</div>
								)
							})}	
						</div>
					</div>
				</div>
			</form>
			<div className="multiplayer-pane">
				<fieldset>
					<legend>Players</legend>
					<div className="game-setup-players-container" ref={parent}>
						{props.users
						.sort((a, b) => b.host ? 1 : -1)
						.map((profileProps, index) => <Profile key={index} {...profileProps} />)
						}
					</div>
				</fieldset>
			</div>
			<div className="invite-container">
				<h2>Invite friends</h2>
				<div className="box game-setup-settings-container">
					<span>scan</span>
					<QRCode className="qr-code" value={props.inviteURL}/>
					<span>or</span>
					<div className="button-wrapper-cool">
						<Button
							className="game-button-copy"
							onClick={() => {
								navigator.clipboard.writeText(props.inviteURL);
								alert("Link copied to clipboard!");
							}}
						>
							Copy link
						</Button>
					</div>
				</div>
			</div>
		</div>
		<div className="button-wrapper-cool">
				<Button className="game-button-start" type="submit" form="gameForm" disabled={!props.isHost} title={props.isHost ? "Start the game" : "Only the host can start the game"}>
					Start game
				</Button>
		</div>
		<KeyListener onKeyPressed={props.formSubmit} listenKey={" "} disabled={!props.isHost}/>
	</main>
}
